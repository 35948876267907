import { useEffect, useMemo, useRef, useState } from "react";
import { Button, Collapse, Dialog, DialogActions, DialogContent, Divider, DialogContentText, DialogTitle, IconButton, Tab, Tabs, TextField, Tooltip, CircularProgress, Fade, Chip, Paper } from "@mui/material";
import { AutorenewOutlined, Delete, Info, SimCardDownload } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { AgGridReact } from "ag-grid-react";
import "../styles/document-list.css";

import PreviewIcon from "../icons/PreviewIcon";
import RenameIcon from "../icons/RenameIcon";
import DeleteIcon from "../icons/DeleteIcon";
import PlusIcon from "../icons/PlusIcon";
import { useNavigate } from "react-router-dom";
import socket from "../socket";
import FormCloseIcon from "../icons/FormCloseIcon";
import DocumentPreview from "./DocumentPreview";
import CircularLoading from "./CircularLoading";
import { toast } from "react-toastify";
import { useUploadQueue } from "../contexts/UploadQueueContext";
import CrossCircleIcon from "../icons/CrossCircleIcon";
import DeleteCofirmIcon from "../icons/DeleteConfirmIcon";
import EditDocumentIcon from "../icons/EditDocumentIcon";
import DocumentTypeIcon from "../icons/DocumentTypeIcon";
import FillButton from "./FillButton";
import { CustomTextField } from "./CustomMUIInputs";
import Lottie from "react-lottie";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import deleteAnimation from "../animations/deleteAnimation.json";
import useResponsiveView from "../hooks/useResponsiveView";
import CustomGridPagination from "./CustomGridPagination";
import { usePlan } from "../contexts/PlanContext";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
  ).toString();

const PreivewRenderer = (params)=>{
    const isMobile = useResponsiveView();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [previewDocument, setPreviewDocument] = useState(null);
    const [cacheDocument, setCacheDocument] = useState(null);
    const [tab, setTab] = useState('info');
    const [pageNumbers, setPageNumbers] = useState(0);
    const containerRef = useRef(null);
    const navigate = useNavigate();
    
    useEffect(()=>{
      if(!open) return;
      
      if(!params.data){
        setPreviewDocument(null);
        return
      }

      setLoading(true);
        
      if(cacheDocument){
          setPreviewDocument(
            URL.createObjectURL(cacheDocument)
          );
          setTimeout(()=>{
            setLoading(false);
          }, 100)
          return;
      }

      socket.emit("request-file", {_id:params.data._id})
      socket.on("update-file", (file)=>{
        const bytesArray = new Uint8Array(file.filedata);
        const fileBlob = new Blob([bytesArray], {type : file.type});
        const url = URL.createObjectURL(fileBlob);
        setCacheDocument(fileBlob);
        setPreviewDocument(url);
        setLoading(false);
      })

      return ()=>{
        socket.off("update-file");
      }
    }, [open, params.data, cacheDocument, navigate])

    const handleClose = () => {
        setOpen(false);
        setPreviewDocument(null)
        URL.revokeObjectURL(previewDocument);
    }
    
    return (
        <div>

            <Dialog fullWidth open={open} onClose={handleClose} 
                PaperProps={{
                    style:{borderRadius:"1rem", padding:isMobile ? "0" : "1rem", height:"80%", maxHeight:"550px", background:"white"},
                }}
            >
                <DialogTitle
                    style={{
                        textAlign:"center",
                        position:"relative"
                    }}
                >
                    <div
                        style={{
                            color:"#1E1E1E",
                            fontFamily:"Montserrat",
                            fontWeight:"700"
                        }}
                    >
                        Preview
                    </div>
                    <Tabs value={tab} onChange={(event, newVal)=>setTab(newVal)} aria-label="basic tabs example"
                        style={{
                            width:"100%"
                        }}
                        sx={{
                            '& .MuiTabs-indicator': {
                                display: 'flex',
                                backgroundColor: '#EA723C',
                                height:"3px",
                            },
                        }}
                        centered
                    >
                        <Tab sx={{fontFamily:"Montserrat", fontSize:"1.1rem", borderBottom:"2px solid #00000080", textTransform:"none", color:"#1E1E1E80", fontWeight:"600", width:"45%", 
                            '&.Mui-selected': {
                                color:"#EA723C",
                                backgroundColor: 'transparent'
                            }
                        }} 
                        label="Info" value={'info'} />
                        <Tab sx={{fontFamily:"Montserrat", fontSize:"1.1rem", borderBottom:"2px solid #00000080", textTransform:"none", color:"#1E1E1E80", fontWeight:"600", width:"45%", 
                            '&.Mui-selected': {
                                color : "#EA723C",
                                backgroundColor: 'transparent'
                            }
                        }} label="Document" value={'file'} />
                    </Tabs>
                    <div style={{position:"absolute", top:"1rem", right:"1rem", cursor:"pointer"}} onClick={handleClose}>
                        <CrossCircleIcon color="#1E1E1E" height="25" width="25" />
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{
                        display:"flex",
                        flexDirection:"column",
                        alignItems:"center"
                    }}
                    ref={containerRef}
                >
                    
                    {
                        tab === 'file' ?
                        (
                        loading ? <CircularLoading /> :
                        previewDocument ? (
                            <div style={{ width: "95%", height: "100%", display:"flex", justifyContent:"center" }}>
                                {
                                    params.data?.filename.endsWith(".pdf") ?
                                    <Document file={previewDocument} onLoadSuccess={({numPages})=>setPageNumbers(numPages)} style={{width:"100%", height:"100%"}} noData={<div>No document available</div>}>
                                        {
                                        Array.from({length : pageNumbers}).map((_, idx)=>{
                                            return <Page loading={()=>{}} width={containerRef.current?.offsetWidth * 0.90} scale={1} pageNumber={idx+1} />
                                        })
                                        }
                                    </Document>
                                    :
                                    <object
                                        data={previewDocument}
                                        width="95%"
                                        height="100%"
                                    />
                                }
                            </div>
                        ) : <div>No document available</div>
                        // <embed src={previewDocument} width={"95%"} height={"100%"} style={{border:"1px double black", background:"white"}} />
                        )
                        : <DocumentPreview document={params.data} />
                    }
                </DialogContent>
            </Dialog>
            
            <Tooltip title="Preview" arrow>
                <IconButton className="action-btn" onClick={()=>setOpen(true)}>
                    <PreviewIcon className="action-svg-icon" />
                </IconButton>
            </Tooltip>
        </div>
    )
}

const DeleteConfirm = ({open, title, prompt, onClose, onDelete}) => {

    return(
        <Dialog fullWidth open={open} onClose={onClose} 
            PaperProps={{
                style:{borderRadius:"1rem", padding:"1rem", background:"white", maxWidth:"30rem"},
            }}
        >

            <DialogTitle
                style={{
                    textAlign:"center",
                    fontFamily:"Montserrat",
                    fontWeight:"700",
                    color:"black",
                    position:"relative"
                }}
            >
                <div>{title}</div>
            </DialogTitle>

            <DialogContent
                style={{
                    display:"flex",
                    flexDirection:"column",
                    alignItems:"center",
                }}
            >
                <Lottie
                    options={{
                        loop : true,
                        autoplay : true,
                        animationData : deleteAnimation,
                        renderer : "svg"
                    }}
                    height={"10rem"}
                    width={"50%"}
                />
                <DialogContentText
                    style={{
                        fontFamily:"Montserrat",
                        marginTop:"0.5rem",
                        color:"black",
                        fontWeight:"600",
                        textAlign:"center",
                        width:"80%"
                    }}
                >{prompt}</DialogContentText>
            </DialogContent>
            <DialogActions style={{display:"flex", justifyContent:"space-evenly"}}>
                <Button 
                    variant="outlined"
                    style={{
                        fontWeight:600, 
                        fontSize:"1rem",
                        background:"white",
                        border:"1px solid black",
                        color:"black",
                        width:"12rem",
                        textTransform : 'none',
                        borderRadius:"16px"
                    }} 
                    onClick={()=>{
                        onClose();
                    }}
                >No</Button>
                <Button 
                    variant="outlined"
                    style={{
                        fontWeight:600, 
                        fontSize:"1rem",
                        background:"#EA723C",
                        border:"1px solid #EA723C",
                        color:"white",
                        width:"12rem",
                        textTransform : 'none',
                        borderRadius:"16px"
                    }} 
                    onClick={()=>{
                        onDelete();
                    }}
                >Yes</Button>
            </DialogActions>
        </Dialog>
    )
}

const ActionsRenderer = (params)=>{
    const [isRenameOpen, setIsRenameOpen] = useState(false);
    const [errorRename, setErrorRename] = useState("");
    const handleRenameClose = () => {
        setIsRenameOpen(false);
        setErrorRename("");
    }

    const handleRename = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const formJson = Object.fromEntries(formData.entries());
        if(!formJson.newName || !formJson.newName.trim()){
            setErrorRename("Empty name not allowed");
            return;
        }
        if(formJson.newName.includes("/")){
            setErrorRename("Invalid name, '/' not allowed");
            return;
        }
        socket.emit("rename-document", {
            _id : params.data._id,
            newName : formJson.newName
        });
        setIsRenameOpen(false);
        setErrorRename("");
    }

    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const handleDeleteClose = ()=>{
        setIsDeleteOpen(false);
    }
    const handleDelete = ()=>{
        socket.emit('delete-document', params.data);
        setIsDeleteOpen(false);
    }

    return (
        <>
            <DeleteConfirm 
                open={isDeleteOpen} 
                title={"Delete Document"}
                prompt={`Are you sure you want to delete the document ${params.data.filename}?`}
                onClose={handleDeleteClose}
                onDelete={handleDelete}
            />

            <Dialog fullWidth open={isRenameOpen} onClose={handleRenameClose} 
                PaperProps={{
                    style:{borderRadius:"1rem", padding:"1rem", background:"white", maxWidth:"30rem"},
                    component:"form",
                    onSubmit:handleRename
                }}
            >
                <DialogTitle
                    style={{
                        textAlign:"center",
                        fontFamily:"Montserrat",
                        fontWeight:"700",
                        color:"black",
                        position:"relative"
                    }}
                >
                    <div>Rename Document</div>
                    <div style={{position:"absolute", top:"1rem", right:"1rem", cursor:"pointer"}} onClick={handleRenameClose}>
                        <CrossCircleIcon color="black" height="25" width="25" />
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{
                        display:"flex",
                        flexDirection:"column",
                        alignItems:"center",
                    }}
                >
                    <CustomTextField 
                        fullWidth
                        id="newName"
                        name="newName"
                        label="New Name"
                        margin="normal"
                        placeholder="Enter Name"
                        defaultValue={params.data.filename}
                        required
                        error={!!errorRename}
                        helperText={errorRename}
                    
                    />
                </DialogContent>
                <DialogActions style={{display:"flex", justifyContent:"center"}}>
                    <Button 
                        type="submit"
                        variant="contained"
                        style={{
                            background:"#EA723C",
                            textTransform:"none",
                            width:"100%",
                            height:"2.8rem",
                            margin:"0 1.5rem 0.5rem 1.5rem",
                            borderRadius:"6px",
                            fontWeight:"600"
                        }}
                    >Rename</Button>
                </DialogActions>
            </Dialog>

            <Tooltip title="Rename" arrow>
                <IconButton className="action-btn" onClick={()=>setIsRenameOpen(true)}>
                    <EditDocumentIcon className="action-svg-icon" />        
                </IconButton>
            </Tooltip>
            
            {
            params.disabled ? 
            <IconButton disabled={params.disabled}>
                <DeleteIcon color={"lightgray"} />        
            </IconButton>
            :
            <Tooltip title="Delete" arrow>
                <IconButton
                    className="action-btn"
                    onClick={(event)=>{
                        setIsDeleteOpen(true);
                    }}
                >
                    <DeleteIcon className="action-svg-icon" />
                </IconButton>
            </Tooltip>
            }
        </>
    )
}

const FilenameRenderer = (params) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div>
            <Tooltip title={params.value} open={isOpen} onClick={()=>setIsOpen(true)} onClose={()=>setIsOpen(false)}
                style={{
                    textOverflow:"ellipsis"
                }}
            >
                <div>
                    {params.value}
                </div>
            </Tooltip>
        </div>
    )
}


const DocumentList = ({documents, updateFlag, updateDocuments, documentType, accessDelete=true, accessUpload=true}) => {
    const isMobile = useResponsiveView();
    const gridAPIRef = useRef(null);
    const [rowData, setRowData] = useState(null);
    const [deletingMultiple, setDeletingMultiple] = useState(false);
    const [confirmMultiDelete, setConfirmMultiDelete] = useState(false);
    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const { resumeQueue, setResumeQueue, jdQueue, setJdQueue } = useUploadQueue();
    const { plan } = usePlan();
    const [documentLimitExceeded, setDocumentsLimitExceede] = useState(false);
    
    useEffect(()=>{
        const maxAllowed = documentType === 'resumes' ? plan?.max_resumes : plan?.max_jds;
        setDocumentsLimitExceede(documents?.filter(x=>x.document_type === documentType).length >= maxAllowed);
    }, [documents, plan])

    const handleUploadDocuments = (event)=>{
        const ACCEPTED_EXTENSIONS = [".pdf", ".txt", ".docx"];
        const MAX_SIZE = 20 * 1024 * 1024;
        const files = event.target?.files;
        console.log(files)
        if(!files){
            return;
        }
        const uploads = {};
        for(let i=0; i<files.length; i++){
            let uploadAllowed = false;
            for(let filetype of ACCEPTED_EXTENSIONS){
                if(files[i].name.endsWith(filetype)){
                    uploadAllowed = true;
                }
            }            
            const file_id = 'id-' + Math.random().toString(36).substr(2, 9) + '-' + Date.now();
            
            if(!uploadAllowed){
                uploads[file_id] = {filename:files[i].name, documentType:documentType, status:"FAILED", error:"unsupported-filetype"}
                continue;
            }
            
            if(files[i].size > MAX_SIZE){
                uploads[file_id] = {filename:files[i].name, documentType:documentType, status:"FAILED", error:"filesize-exceeded"}
                continue;
            }
            
            uploads[file_id] = {filename:files[i].name, file:files[i], documentType:documentType, status:'REQUESTED'};
            socket.emit(
                "upload-document", 
                {
                    file:{filename:files[i].name, filedata:files[i]}, 
                    typeCheck:true, 
                    documentType:documentType, 
                    file_id:file_id
                }
            )
        }
        if(documentType === 'resumes'){
            setResumeQueue({...resumeQueue, ...uploads});
        }else{
            setJdQueue({...jdQueue, ...uploads})
        }
        event.target.value = '';
    }
    
    useEffect(()=>{

        window.onbeforeunload = (event)=>{
            event.preventDefault();
        }
        
        setRowData(
            documents?.filter(x=>x.document_type === documentType)
        )

        socket.on("delete-multi-success", (numberOfDocuments)=>{
            setDeletingMultiple(false);
            toast.success(numberOfDocuments + " file(s) deleted successfully", {closeOnClick:true})
        })

        socket.on("delete-success", (filename)=>{
            toast.success("File deleted successfully", {closeOnClick:true})
        })
        
        return ()=>{
            socket.off("delete-multi-success");
            socket.off("delete-success");
        }
    }, [documents, documentType]);

    const [columnDefs, setColumnsDefs] = useState([
        { headerName: 'Name', 
            sortable: true, 
            filter: true, 
            field:"filename",
            flex:5,
            minWidth : isMobile ? 350 : undefined,
            headerClass:"not-centered-header-cell",
            cellStyle : {
                fontSize:isMobile ? "1rem": "1.1rem",
                textAlign : "left",

            },
            cellRenderer:FilenameRenderer,
            cellClass:"name-cell",
            checkboxSelection:true, headerCheckboxSelection:true, headerCheckboxSelectionFilteredOnly:true,
        },
        { headerName: 'Preview', cellClass:"centered-cell", cellRenderer:PreivewRenderer },
        // { headerName: 'Rename', cellClass:"centered-cell", cellRenderer:RenameRenderer },
        // { headerName: 'Delete', cellClass:"centered-cell", cellRenderer:DeleteRenderer, cellRendererParams:{disabled:!accessDelete} },
        { headerName: 'Actions', cellClass:"centered-cell", cellRenderer:ActionsRenderer, cellRendererParams:{disabled:!accessDelete} },
    ])

    useEffect(()=>{
        setColumnsDefs(prev=>prev.map(value=>{
            return value.headerName === "Actions" ?
            {
                ...value,
                cellRendererParams:{disabled:!accessDelete}
            }
            : value
        }))
    }, [accessDelete])

    return (
        <>
            {
                isMobile ? 
                // Mobile View
                <div className="home-container" style={{height:"calc(100svh - 4rem - 2.5svh - 1rem - 3rem)"}}>
                    <div className="document-list-header-buttons">
                        <div
                            style={{
                                display:"flex",
                                flexDirection:"column",
                                width:"70%"
                            }}
                        >
                            <input type="file" name="uploadDocuments" id={"uploadDocuments-"+documentType} multiple style={{display:"none"}} 
                                accept=".pdf, .docx, .txt"
                                onChange={handleUploadDocuments}
                            />

                            <FillButton 
                                variant="outlined" 
                                disabled={!accessUpload || documentLimitExceeded}
                                className={`add-document-btn ${accessUpload ? null : "disabled"}`}
                                startIcon={documentLimitExceeded ? undefined : <PlusIcon className="adddoc-svg-icon" />}
                                onClick={()=>document.getElementById("uploadDocuments-"+documentType).click()}
                                id={documentType + 'UploadButton'}
                                value={documentLimitExceeded ? documentType === 'resumes' ? "Max Resumes": "Max Job Descriptions" : "Upload Document"}
                            />
                            <div className="document-list-header-helper" style={{padding:"0.5rem 0rem"}}><DocumentTypeIcon /> <p>txt, docx and pdf, &lt;= 20MB</p></div>
                        </div>
                        {
                            selectedDocuments.length ?
                            <LoadingButton
                                variant="contained" 
                                style={{
                                    background:accessDelete ?"#FF4848" : "lightgray",
                                    border:"1px solid " + (accessDelete ? "#FF4848" : "lightgray"),
                                    textTransform:"none",
                                    borderRadius:"0.5rem",
                                    fontFamily:"Montserrat",
                                    padding:"0.3rem",
                                    height:"2.5rem",
                                    alignSelf:"flex-start"
                                }}
                                type="input"
                                inputMode="file"
                                onClick={()=>{
                                    setConfirmMultiDelete(true);
                                }}
                                loadingIndicator={
                                    <CircularProgress color="inherit" sx={{color:"white"}} size={16} />
                                }
                                loading={deletingMultiple}
                                disabled={!accessDelete}
                            >
                                <DeleteIcon color="white" />
                            </LoadingButton>
                            : null
                        }
                    </div>

                    <div className={"ag-theme-quartz" + (isMobile ? " mobile" : "")} style={{ width: '100%', borderRadius:"16px", flexGrow:1}}>
                        <Fade in={updateFlag} mountOnEnter unmountOnExit>
                            <div
                                style={{
                                    position:"absolute",
                                    width:"calc(95% - 1rem)",
                                    display:"flex",
                                    justifyContent:"center",
                                    zIndex:"5",
                                    marginTop:"3.3rem",
                                }}
                            >
                                <Chip 
                                    icon={<AutorenewOutlined style={{color:"white"}} />} 
                                    onClick={updateDocuments} 
                                    style={{background:"#EA723C", font:"inherit", color:"white"}} 
                                    label={"Load new documents"} 
                                />
                            </div>
                        </Fade>
                        <AgGridReact 
                            pagination={true} 
                            defaultColDef={{wrapHeaderText:true, minWidth:150, flex:1, resizable: false, sortable:false, cellStyle:{textAlign:"center"}}}
                            rowData={rowData} 
                            columnDefs={columnDefs}
                            rowHeight={"60px"}
                            getRowId={(params)=>params.data._id}
                            rowSelection='multiple'
                            suppressRowClickSelection
                            onRowSelected={(event)=>{
                                setSelectedDocuments(event.api.getSelectedRows());
                            }}
                            suppressCellFocus
                            onGridReady={(params)=>gridAPIRef.current = params.api}
                            suppressPaginationPanel
                            paginationPageSize={100}
                        />
                    </div>
                    <CustomGridPagination gridAPI={gridAPIRef.current} />
                </div>

                :
                
                // Desktop View
                <div className="document-list-container">
                    <Paper
                        elevation={1}
                        sx={{
                            width:"93%",
                            marginTop:"0.5rem",
                            padding:"0.5rem 1rem",
                            height:"100%",
                            borderRadius:"32px",
                            display:"flex",
                            flexDirection:"column"
                        }}
                    >
                        <DeleteConfirm 
                            open={confirmMultiDelete}
                            title={"Remove Document"}
                            prompt={`Are you sure you want to remove ${selectedDocuments.length} selected document(s)?`}
                            onClose={()=>setConfirmMultiDelete(false)}
                            onDelete={()=>{
                                setConfirmMultiDelete(false);
                                socket.emit("delete-documents", selectedDocuments);
                                setDeletingMultiple(true);
                            }}
                        />

                        <div className="document-list-header-buttons">
                            <div
                                style={{
                                    display:"flex",
                                    flexGrow:1,
                                    alignItems:"center"
                                }}
                            >
                                <input type="file" name="uploadDocuments" id={"uploadDocuments-"+documentType} multiple style={{display:"none"}} 
                                    accept=".pdf, .docx, .txt"
                                    onChange={handleUploadDocuments}
                                />

                                <FillButton 
                                    variant="outlined" 
                                    disabled={!accessUpload || documentLimitExceeded}
                                    className={`add-document-btn ${accessUpload ? null : "disabled"}`}
                                    startIcon={documentLimitExceeded ? undefined : <PlusIcon className="adddoc-svg-icon" />}
                                    onClick={()=>document.getElementById("uploadDocuments-"+documentType).click()}
                                    id={documentType + 'UploadButton'}
                                    value={documentLimitExceeded ? documentType === 'resumes' ? "Max Resumes": "Max Job Descriptions" : "Upload Document"}
                                />
                                <div className="document-list-header-helper"><DocumentTypeIcon  /> <div> <p>supported filetypes : txt, docx and pdf</p> <p>max filesize : 20MB</p></div></div>
                            </div>
                            {
                                selectedDocuments.length ?
                                <LoadingButton
                                    variant="contained" 
                                    style={{
                                        background:accessDelete ?"#FF4848" : "lightgray",
                                        border:"1px solid " + (accessDelete ? "#FF4848" : "lightgray"),
                                        textTransform:"none",
                                        borderRadius:"12px",
                                        fontFamily:"Montserrat",
                                        fontSize:"1rem",
                                        height: "2.5rem"
                                    }}
                                    startIcon={<DeleteIcon color="white" />}
                                    type="input"
                                    inputMode="file"
                                    onClick={()=>{
                                        setConfirmMultiDelete(true);
                                    }}
                                    loadingIndicator={
                                        <CircularProgress color="inherit" sx={{color:"white"}} size={16} />
                                    }
                                    loading={deletingMultiple}
                                    disabled={!accessDelete}
                                >
                                    Delete Selected
                                </LoadingButton>
                                : null
                            }
                        </div>

                        <div className="ag-theme-quartz" style={{ width: 'calc(1rem + 100%)', height:"100%", marginLeft:"-0.5rem" }}>
                            <Fade in={updateFlag} mountOnEnter unmountOnExit>
                                <div
                                    style={{
                                        position:"absolute",
                                        width:"calc(95% - 1rem)",
                                        display:"flex",
                                        justifyContent:"center",
                                        zIndex:"5",
                                        marginTop:"3.3rem",
                                    }}
                                >
                                    <Chip 
                                        icon={<AutorenewOutlined style={{color:"white"}} />} 
                                        onClick={updateDocuments} 
                                        style={{background:"#EA723C", font:"inherit", color:"white"}} 
                                        label={"Load new documents"} 
                                    />
                                </div>
                            </Fade>
                            <AgGridReact 
                                pagination={true} 
                                defaultColDef={{wrapHeaderText:true, minWidth:100, flex:1, resizable: false, sortable:false, cellStyle:{textAlign:"center"}}}
                                rowData={rowData} 
                                columnDefs={columnDefs}
                                rowHeight={"60px"}
                                getRowId={(params)=>params.data._id}
                                rowSelection='multiple'
                                suppressRowClickSelection
                                onRowSelected={(event)=>{
                                    setSelectedDocuments(event.api.getSelectedRows());
                                }}
                                suppressCellFocus
                                onGridReady={(params)=>gridAPIRef.current = params.api}
                                suppressPaginationPanel
                                paginationPageSize={100}
                            />
                        </div>
                        <CustomGridPagination gridAPI={gridAPIRef.current} />
                    </Paper>
                </div>
            }
        </>
    )
}

export default DocumentList;