import socket from "../socket.js";
import { createContext, useContext, useEffect, useState } from "react";

const PlanContext = createContext();
export const usePlan = () => useContext(PlanContext);

export const PlanProvider = ({children}) => {
    const [plan, setPlan] = useState(null);

    useEffect(()=>{
        fetch("/auth/plan")
        .then(response=>{
            if(response.status !== 200){
                console.log(response);
                return null;
            }
            return response.json();
        })
        .then(data=>{
            setPlan(data);
        })
        .catch(error=>{
            console.log(error);
        })
    }, []);

    return (
        <PlanContext.Provider value={{plan}}>
            {children}
        </PlanContext.Provider>
    )
}